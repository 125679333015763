.container {
  @apply mb-10;
}

.loadingContainer {
  display: flex;
  align-items: center;
  @apply pt-4;
}

.noResultContainer {
  text-align: center;
  @apply text-gray-600;
}
