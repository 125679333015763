.container {
  @apply mt-2;

  &:first-of-type {
    a {
      @apply mt-0;
    }
  }
}

.box {
  display: block;
  @apply p-4;
  @apply bg-gray-200;
  color: inherit;
  font-weight: inherit;
  border-radius: 4px;
}

.box:hover,
.box:focus {
  text-decoration: none;
}

.date {
  display: block;
}

.box:hover .date,
.box:focus .date {
  @apply text-black;
}

.title {
  @apply text-lg;
  @apply m-0;
  @apply text-green-800;
}

.box:hover .title,
.box:focus .title {
  text-decoration: underline;
}
